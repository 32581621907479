import {FiGithub, FiGlobe, FiLinkedin,} from 'react-icons/fi';
import AppFooterCopyright from './AppFooterCopyright';

const socialLinks = [
    {
        id: 1,
        icon: <FiGlobe/>,
        url: 'https://medium.com/@denisa_k',
    },
    {
        id: 2,
        icon: <FiLinkedin/>,
        url: 'https://www.linkedin.com/in/denisakita/',
    },
    {
        id: 3,
        icon: <FiGithub/>,
        url: 'https://github.com/denisakita',
    },
    // {
    //     id: 4,
    //     icon: <FiInstagram/>,
    //     url: 'https://www.instagram.com/denisa_kita/',
    // },
];

const AppFooter = () => {
    return (
        <div className="mx-auto flex flex-col justify-between min-h-[150px] max-sm:mt-6">
            <div
                className="text-xl sm:text-4xl text-primary-light w-full inline-flex flex-row justify-center mt-[0.25px]">
                <p className="max-w-fit">
                    Follow me
                </p>
            </div>
            {/* Footer social links */}
            <div className="font-general-regular flex flex-col justify-center items-center">

                <ul className="flex gap-4 sm:gap-8">
                    {socialLinks.map((link) => (
                        <a
                            href={link.url}
                            target="__blank"
                            key={link.id}
                            className="cursor-pointer rounded-lg bg-gray-50 text-chinese-violet sm:hover:bg-rich-black shadow-sm p-2 duration-300"
                        >
                            <i className="text-xl sm:text-2xl md:text-3xl">
                                {link.icon}
                            </i>
                        </a>
                    ))}
                </ul>
            </div>
            <AppFooterCopyright/>
        </div>
    );
};

export default AppFooter;
