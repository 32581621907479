function AppFooterCopyright() {
    return (
        <div className="font-general-regular flex justify-center items-center text-center">
            <div className="text-lg text-ternary-light">
                &copy; {new Date().getFullYear()}
                <a
                    href="https://www.linkedin.com/in/denisakita/"
                    target="__blank"
                    className="hover:underline  ml-1 duration-500"
                >
                    Powered by
                </a>

                <a
                    href="https://www.linkedin.com/in/denisakita/"
                    target="__blank"
                    className="text-secondary-light font-medium uppercase hover:underline ml-1 duration-500"
                >
                    denisa kita
                </a>
            </div>
        </div>
    );
}

export default AppFooterCopyright;
