import {AnimatePresence} from 'framer-motion';
import {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import AppFooter from './components/shared/AppFooter';
import AppHeader from './components/shared/AppHeader';
import './css/App.css';
import UseScrollToTop from './hooks/useScrollToTop';
import Video from './images/backgrounds/background1.mp4';


const About = lazy(() => import('./pages/AboutMe'));
const Contact = lazy(() => import('./pages/Contact.jsx'));
const Home = lazy(() => import('./pages/Home'));
const Projects = lazy(() => import('./pages/Projects'));


function App() {
    return (
        <AnimatePresence>
            <div className="relative max-h-screen">
                {/* Background Video */}
                <video
                    autoPlay
                    loop
                    muted
                    className="fixed inset-0 w-full h-full object-cover z-0"
                >
                    <source src={Video} type="video/mp4"/>
                    {/* Add additional source elements for other video formats if needed */}
                    Your browser does not support the video tag.
                </video>

                {/* Container for Content, Nav, and Footer */}
                <div className="container mx-auto relative z-10">
                    <Router>
                        <ScrollToTop/>
                        <div className="max-h-[95vh] min-h-[95vh] flex flex-col justify-between">
                            <AppHeader/>
                            <Suspense fallback={""}>
                                <Routes>
                                    <Route path="/" element={<Home/>}/>
                                    <Route path="projects" element={<Projects/>}/>

                                    <Route path="about" element={<About/>}/>
                                    <Route path="contact" element={<Contact/>}/>
                                </Routes>
                            </Suspense>
                            <AppFooter/>
                        </div>
                    </Router>
                    <UseScrollToTop/>
                </div>
            </div>
        </AnimatePresence>
    );
}

export default App;
