import {useState} from 'react';
import {FiMenu, FiX} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import HireMeModal from '../HireMeModal';
import logoDark from '../../images/favicon.png';
import {motion} from 'framer-motion';
import Button from '../reusable/Button';

const AppHeader = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [showModal, setShowModal] = useState(false);

    function toggleMenu() {
        setShowMenu(!showMenu);
    }

    function toggleHireMeModal() {
        const htmlElement = document.getElementsByTagName('html')[0];
        if (!showModal) {
            htmlElement.classList.add('overflow-y-hidden');
        } else {
            htmlElement.classList.remove('overflow-y-hidden');
        }
        setShowModal(!showModal);
    }

    return (
        <motion.nav
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            id="nav"
            className="sm:container sm:mx-auto"
        >
            <div className="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center">
                <div className="flex justify-between items-center px-4 sm:px-0">
                    <div>
                        <Link to="/">
                            <img src={logoDark} className="w-40" alt="Dark Logo"/>
                        </Link>
                    </div>
                    <div className="sm:hidden">
                        <button
                            onClick={toggleMenu}
                            type="button"
                            className="focus:outline-none"
                            aria-label="Hamburger Menu"
                        >
                            {showMenu ? <FiX className="text-3xl"/> : <FiMenu className="text-3xl text-white"/>}
                        </button>
                    </div>
                </div>
                <div
                    className={
                        showMenu
                            ? 'block m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none'
                            : 'hidden'
                    }
                >
                    <Link
                        to="/projects"
                        className="block text-left text-lg text-primary-light sm:mx-4 mb-2 sm:py-2"
                        aria-label="Projects"
                    >
                        Projects
                    </Link>
                    <Link
                        to="/about"
                        className="block text-left text-lg text-primary-light sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light"
                        aria-label="About Me"
                    >
                        About Me
                    </Link>
                    <Link
                        to="/contact"
                        className="block text-left text-lg text-primary-light sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light"
                        aria-label="Contact"
                    >
                        Contact
                    </Link>
                    <div className="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light">
                        <span
                            onClick={toggleHireMeModal}
                            className="font-general-medium sm:hidden block text-left text-chinese-violet text-md bg-secondary-light shadow-sm rounded-sm px-4 py-2 mt-2 duration-300 w-24"
                            aria-label="Hire Me Button"
                        >
                            <Button title="Hire Me"/>
                        </span>
                    </div>
                </div>
                <div
                    className="font-general-medium hidden m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none">
                    <Link
                        to="/about"
                        className="block text-left text-lg text-primary-light hover:text-third-light sm:mx-4 mb-2 sm:py-2"
                        aria-label="About Me"
                    >
                        About Me
                    </Link>
                    <Link
                        to="/projects"
                        className="block text-left text-lg text-primary-light hover:text-third-light sm:mx-4 mb-2 sm:py-2"
                        aria-label="Projects"
                    >
                        Projects
                    </Link>
                    <Link
                        to="/contact"
                        className="block text-left text-lg text-primary-light hover:text-third-light sm:mx-4 mb-2 sm:py-2"
                        aria-label="Contact"
                    >
                        Contact
                    </Link>
                </div>
                <div className="hidden sm:flex justify-between items-center flex-col md:flex-row">
                    <div className="hidden md:flex">
                        <span
                            onClick={toggleHireMeModal}
                            className="text-md font-general-medium bg-white hover:bg-gray-200 text-chinese-violet shadow-sm rounded-md px-5 py-2.5 duration-300"
                            aria-label="Hire Me Button"
                        >
                            <Button title="Hire Me"/>
                        </span>
                    </div>
                </div>
            </div>
            {showModal && (
                <HireMeModal
                    onClose={toggleHireMeModal}
                    onRequest={toggleHireMeModal}
                />
            )}
        </motion.nav>
    );
};

export default AppHeader;
